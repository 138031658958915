.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-form {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.login-form-field {
    margin-bottom: 16px;
    padding-right: 20px;
}

label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.login-input-field input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-input-field .input-error {
    border-color: red;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}

.login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-button:hover {
    background-color: #0066da;
}

.login-error {
    color: red;
    margin-top: 16px;
}

.login-page {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f7f7f7;
}

.login-info {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.fill-button {
    font-size: 12px;
    color: #333;
    background-color: #e0e0e0;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.fill-button:hover {
    background-color: #d5d5d5;
}

.copy-notification {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    opacity: 0.9;
    z-index: 1000;
    transition: opacity 0.5s ease-in-out;
}

.login-error {
    color: red;
    margin-top: 20px;
}
