@font-face {
    font-family: 'franklin-gothic';
    src: url('./assets/fonts/FranklinGothic.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

body {
    font-family: 'Montserrat', sans-serif;
    margin: 0; /* Убираем отступ снизу */
    padding: 0; /* Убираем внутренние отступы */
    min-height: 100vh; /* Минимальная высота страницы */
    display: flex;
    flex-direction: column;
    background-color: rgba(211, 180, 155, 0.21);

}

.app {
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* 100vh, чтобы футер был внизу */
}


main {
    flex: 1;
    /*padding: 1rem;*/
}

/*"Montserrat", sans-serif*/
/* Стили для WebKit-браузеров (Chrome, Safari) */
::-webkit-scrollbar {
    width: 16px; /* Ширина скроллбара */
    height: 16px; /* Ширина скроллбара */
    background-color: rgba(211, 180, 155, 0.21); /* Фон скроллбара */
}

::-webkit-scrollbar-thumb {
    background-color: #e97415; /* Оранжевый цвет для ползунка */
    border-radius: 10px; /* Закругленные края ползунка */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #d86410; /* Более темный оранжевый при наведении */
}

/* Стили для Firefox */
html {
    scrollbar-width: auto; /* Узкий скроллбар */
    scrollbar-color: #e97415 rgba(211, 180, 155, 0.21); /* Оранжевый ползунок и фон скроллбара */
}

/* Скрыть скроллбар на мобильных устройствах */
@media (max-width: 768px) {
    /* Для WebKit-браузеров (Chrome, Safari) */
    ::-webkit-scrollbar {
        display: none; /* Полностью скрыть скроллбар */
    }

    /* Для Firefox */
    html {
        scrollbar-width: none; /* Скрыть скроллбар */
    }
}

/* Анимация "выключения телевизора" с горизонтальным сжатием и вспышкой */
@keyframes fadeOut {
    0% {
        opacity: 1; /* Начинаем с полной видимости */
    }
    100% {
        opacity: 0; /* Полное исчезновение */
    }
}

/* Класс для анимации плавного исчезновения */
.animate-fade-out {
    animation: fadeOut 1.4s ease forwards; /* Длительность 0.8с */
}

/* Специальная версия для мобильных устройств, если нужно */
@media (max-width: 768px) {
    @keyframes fadeOut {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
}
